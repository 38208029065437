/* src/styles.css */
body {
    font-family: 'Arial', sans-serif;
    background-color: #282c34;
    color: white;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100vh;
  }
  
  .page {
    text-align: center;
  }
  
  a {
    color: #61dafb;
  }
  
  a:hover {
    color: #21a1f1;
  }
/* Sidebar specific styles */
.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #333;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar li {
    padding: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s;  /* Smooth transition for background color */
}

.sidebar li.active {
    background-color: #444;  /* Darker background for the active tab */
    box-shadow: none;
}

.sidebar li.active a {
    background-color: #444;
    color: white;
    pointer-events: none;
}

.sidebar a {
    color: lightblue;
    text-decoration: none;
    display: block;  /* Ensures the link fills the list item area */
    transition: color 0.3s;  /* Smooth transition for color */
}

.sidebar a:hover {
    color: #21a1f1;  /* Highlight color on hover */
    text-decoration: none;  /* No underline on hover */
}
